<template lang="pug">
  #home
    #board.px-4.px-md-5.pt-5
      .row.title.d-flex.mx-0.mt-md-5.mb-5
        h1.light-font PLATAFORMA
        h1.bold-font CIUDADANA DE FOSAS
      .row.intro.subtitle.d-flex.mx-0.my-5.pt-md-5.px-md-5
        p.mb-0.bold-font Introducción
      .row.intro-text.d-flex.light-font.mx-0.mt-3.px-md-5
        | <p class="mb-4">México vive una crisis de derechos humanos con más de 115 mil personas desaparecidas al 13 de mayo de 2024 como consecuencia de la militarización de la seguridad pública que inició en el sexenio de Felipe Calderón, siguió con el de Enrique Peña Nieto y se ha intensificado con el de Andrés Manuel López Obrador. A pesar de la magnitud del fenómeno de las desapariciones, existen pocos datos disponibles por parte del gobierno mexicano. Si bien hay un <a href="https://versionpublicarnpdno.segob.gob.mx/Dashboard/Index" target="_blank">Registro Nacional de Personas Desaparecidas</a> y No Localizadas (RNPDNO), los nombres, las fotografías, las señas particulares y otros datos relevantes para la localización de las más de cien mil personas desaparecidas no son datos abiertos.</p>
        p.mb-4 Es habitual que las tareas de búsqueda recaigan en las familias de las personas desaparecidas, aunque por ley corresponde al Estado encontrarlas. Las familias se movilizan para elaborar fichas con datos básicos, pegar carteles y publicar la información referente a sus seres queridos en redes sociales. Asimismo, son ellas quienes empujan leyes locales y nacionales en la materia, o elaboran sus propias bases de datos y sus informes. Las familias, mayoritariamente mujeres, madres, hermanas e hijas, son quienes indagan sobre el paradero de sus seres queridos y siguen el rastro de sus huellas, poniendo en riesgo su integridad física y psicoemocional.
        p.mb-4 En esas búsquedas, los hallazgos de fosas clandestinas han sido recurrentes y extendidos en la mayoría de las entidades de México desde el año 2007. El fenómeno refleja el grado de descomposición social y deshumanización alcanzado como producto de una política de drogas punitiva y no preventiva, el uso de la fuerza militar y un sistema de procuración de justicia incapaz de investigar y perseguir eficazmente a los responsables de graves violaciones a los derechos humanos.
        | <p class="mb-4">Esta Plataforma Ciudadana de Fosas nació como un ejercicio ciudadano en <a href="https://articulo19.org/lanzamiento-de-la-plataforma-ciudadana-de-fosas-clandestinas-por-parte-de-article-19-cmdpdh-ibero-data-civica-y-hrdag" target="_blank">septiembre de 2021</a> ante la falta de una versión pública del Registro Nacional de Fosas Comunes y de Fosas Clandestinas, que, según la <a href="https://www.diputados.gob.mx/LeyesBiblio/pdf/LGMDFP.pdf" target="_blank">Ley General en Materia de Desaparición Forzada de Personas, Desaparición Cometida por Particulares y del Sistema Nacional de Búsqueda de Personas</a>, es responsabilidad de la Fiscalía General de la República (FGR) en colaboración con las fiscalías y procuradurías estatales.</p>
        | <p class="mb-4">En marzo de 2023, la Comisión Nacional de Búsqueda (CNB) hizo público el <a href="https://hallazgosfosasclandestinas.segob.gob.mx" target="_blank">Mapa de Hallazgos de Fosas Clandestinas</a> con datos solicitados por dicha institución a la FGR y las fiscalías estatales. En este mapa, actualizado hasta abril de 2023, la persona usuaria puede conocer el número de fosas por estado y por municipio. Sin embargo, la herramienta no da cuenta del número de cuerpos y/o fragmentos humanos recuperados. Tampoco brinda la información de todas las fiscalías, incluyendo solo de aquellas que deciden transparentar. Aunque es un avance significativo, no muestra el panorama completo. Junto con el mapa, pero en otro sitio web, la CNB publicó un <a href="https://comisionacionaldebusqueda.gob.mx/registro-de-fosas-clandestinas" target="_blank">registro</a> con datos de fosas clandestinas, el problema es que al día de hoy solo aparece actualizado hasta agosto de 2023 y con información de menos de la mitad de las entidades federativas. Es decir, solo con datos de 15 de las 32 entidades en México.</p>
        p.mb-4 Contrario al esfuerzo de la CNB, la Plataforma Ciudadana de Fosas registra hallazgos en todos los estados del país, siendo una herramienta de información ante ese vacío por parte de las autoridades. Pero sobre todo, esta plataforma es una herramienta dirigida a los cientos de familias y colectivos de búsqueda en México, así como a la sociedad en general.
        p.mb-4 La plataforma reúne documentación de los hallazgos de fosas clandestinas en México reportadas por 1) prensa nacional y local; 2) fiscalías, procuradurías locales y servicios forenses, como el caso de Jalisco; y 3) Fiscalía General de la República (FGR), estas últimas obtenidas vía Transparencia y Acceso a la Información Pública. En resumen, aquí se encontrarán los hallazgos de fosas clandestinas de fuentes oficiales desde el año 2006 hasta junio de 2023, además de los registros de fosas clandestinas obtenidos por fuentes abiertas —hemerográficas— desde el año 2006 hasta diciembre de 2022.
        p.mb-4 Esta herramienta ciudadana muestra el número de fosas clandestinas por entidad y por municipio; el número de cuerpos, de restos y/o fragmentos óseos de personas desaparecidas y, en los casos que logramos obtener la información, el sexo de las víctimas. En la pestaña de “Repositorio” se podrán descargar las solicitudes de información y las respuestas de las instituciones públicas.
        p.mb-4 Por último, cabe señalar que el procesamiento de la información implicó un reto debido a la diferencia en el número de fosas reportadas por los distintos sujetos obligados, lo cual resulta preocupante porque muestra que la sociedad sigue sin tener certeza de la magnitud del fenómeno de fosas clandestinas en México. Las discrepancias entre fuentes oficiales es un indicio de la falta de interoperabilidad entre instituciones, cuando estas deberían trabajar de manera conjunta en la búsqueda de personas desaparecidas. En el caso de las fuentes abiertas, los datos obtenidos de la prensa tienen sus limitantes porque no pueden dar cuenta del total de hallazgos de fosas; sin embargo, resultan útiles para contrastar y evaluar los datos oficiales. Ya que, sabemos, algunas de las fosas clandestinas son documentadas por la prensa, pero no todas son registradas por las instituciones.

    .video.mb-5
      iframe(
        :width="videoWidth"
        :height="videoHeight"
        src="https://www.youtube.com/embed/zAZz4XwElME"
        title="Conoce la nueva Plataforma Ciudadana de Fosas Clandestinas"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      )
    .video.mb-5
      iframe(
        :width="videoWidth"
        :height="videoHeight"
        src="https://www.youtube.com/embed/7jC6MgYijR8"
        title="Conoce la nueva Plataforma Ciudadana de Fosas Clandestinas"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      )
    .video
      iframe(
        :width="videoWidth"
        :height="videoHeight"
        src="https://www.youtube.com/embed/xkrlc4_El7Y"
        title="Violencia y Terror. Hallazgos sobre Fosas Clandestinas en México 2006-2017"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      )
    #instructions
      .row.instructions.subtitle.d-flex.mx-0.px-5.my-5.pb-md-4
        p.mb-0.bold-font ¿Cómo funciona la plataforma?
      .row.instructions-text.d-flex.mx-0.mt-5.pb-5.px-md-5
        .col-12.col-md-6.instructions-container
          .instruction.px-3.px-md-5.py-4
            .row.image.d-flex.mx-0
              .col-2
                span 1
              .col-10.img-container
                img.img-fluid(src="@/assets/instructions/Paso 1.png")
            .row.text.mx-0.pt-4
              p.mb-0 Usa el selector de fuentes para conocer las fosas que una o más fuentes han
                |  reportado.
                span  (Recuerda que una misma fosa puede ser reportada por dos fuentes o más)
        .col-12.col-md-6.instructions-container
          .instruction.px-3.px-md-5.py-4
            .row.image.d-flex.mx-0
              .col-2
                span 2
              .col-10.img-container
                img.img-fluid(src="@/assets/instructions/Paso 2.png")
            .row.text.mx-0.pt-4
              p.mb-0 Consulta toda la información disponible o filtra los resultados según los
                |  años de hallazgo reportados.
        .col-12.col-md-6.instructions-container
          .instruction.px-3.px-md-5.py-4
            .row.image.d-flex.mx-0
              .col-2
                span 3
              .col-10.img-container
                img.img-fluid(src="@/assets/instructions/Paso 3.png")
            .row.text.mx-0.pt-4
              p.mb-0 Busca el estado o municipio de tu interés o da click directamente sobre él
                |  en el mapa.
        .col-12.col-md-6.instructions-container.not-aligned
          .instruction.px-3.px-md-5.py-4
            .row.image.d-flex.mx-0
              .col-2
                span 4
              .col-10.img-container
                .img-container
                  img.img-fluid(src="@/assets/instructions/Paso 4.png")
            .row.text.mx-0.pt-4
              p.mb-0 Consulta las cifras agregadas y desagregadas reportadas por cada autoridad
                |  para el municipio o estado seleccionado.
        .col-12.col-md-6.instructions-container.not-aligned
          .instruction.px-3.px-md-5.py-4
            .row.image.d-flex.mx-0
              .col-2
                span 5
              .col-10.img-container
                img.img-fluid(src="@/assets/instructions/Paso 5.png")
            .row.text.mx-0.pt-4
              p.mb-0 Consulta las estimaciones más recientes para conocer la probabilidad de
                |  encontrar nuevos hallazgos en el municipio seleccionado.
        .col-12.col-md-6.instructions-container.pb-4
          .instruction.px-3.px-md-5.py-4
            .row.image.d-flex.mx-0
              .col-2
                span 6
              .col-10.img-container
                img.img-fluid(src="@/assets/instructions/Paso 6.png")
            .row.text.mx-0.pt-4
              p.mb-0 Para conocer más, consulta nuestra nota metodológica y base de datos oficial,
                |  así como nuestro repositorio digital con los registros y solicitudes de
                |  información que alimentan este proyecto.
      .row.go-to-map.subtitle.d-flex.mx-0.px-5.pb-5
        router-link.mb-0.bold-font(to="/tablero") Ir al Tablero

</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      videoWidth: "500",
      videoHeight: "300",
    };
  },
  computed: {
    mobile() {
      return this.$store.state.mobile;
    },
  },
  mounted() {
    this.videoWidth = this.mobile
      ? "100%"
      : document.querySelector("#video").clientWidth / 2;
    this.videoHeight = document.querySelector("#video").clientHeight;
  },
};
</script>

<style lang="scss">
@import "@/global.scss";
#home {
  #board {
    min-height: 80vh;
    background-image: url("../assets/Fondo_DashboardFosas.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    .title {
      margin: auto;
      flex-direction: column;
      justify-content: center;
      h1.bold-font {
        color: $black;
        background-color: #e0e0e0;
        width: fit-content;
        margin: auto;
        padding: 0px 5px;
      }
    }
    .intro-text {
      justify-content: center;
      flex-direction: column;
      p {
        margin: auto;
        width: 40em;
      }
    }
  }
  .subtitle {
    justify-content: center;
    font-size: 18px;
  }
  .video {
    min-width: 100%;
    height: auto;
    min-height: 20%;
  }
  #instructions {
    .instructions-container {
      height: fit-content;
    }
    .not-aligned {
      top: -50px;
      span {
        position: absolute;
        top: 25%;
      }
    }
    .instruction {
      align-self: center;
      .image {
        span {
          font-weight: bold;
          font-size: 2.5em;
        }
        .img-container {
          text-align: left;
        }
      }
      .text {
        font-size: 14px;
        text-align: left;
        margin-left: 20% !important;
      }
    }
    .go-to-map a {
      color: white;
    }
  }
}
@media (max-width: 767.98px) {
  #home {
    #board {
      .title h1 {
        font-size: 28px;
      }
      .intro-text p {
        width: auto;
        font-size: 13px;
      }
    }
    .not-aligned {
      top: 0 !important;
      span {
        position: inherit !important;
        top: 0 !important;
      }
    }
  }
}
</style>
