<template lang="pug">
  #repository.pt-4
    // Desktop Filters
    .repo-container.row.mx-0
      b-overlay(
        :show="showLoading"
        variant="light"
        opacity="0.35"
        blur="2px"
        rounded="sm")
      .filters.col-12.col-md-3(v-if="!mobile")
        .line
        b-form-group.bold-font.mt-3(
          v-for="filter in filters"
          :label="filter.label"
          :key="filter.id"
          :class="filter.customClass"
          v-slot="{ ariaDescribedby }"
        )
          b-form-checkbox.light-bold-font.mt-3(
            v-if="filter.inputType === 'checkbox-group'"
            v-for="child in filter.inputChilds"
            v-model="filter.model"
            @change="filterDocuments()"
            :value="child.key"
            :key="child.key"
            :class="child.key"
            v-bind:style="{ color: child.labelColor }"
            :aria-describedby="ariaDescribedby"
          ) {{ child.value }}
          autocomplete(
            v-if="filter.inputType === 'autocomplete'"
            :filterId="filter.id"
            :items="filter.items ? filter.items : states"
            :value="filter.model"
            :text="filter.optionsValue"
            :action="filter.action",
            :clearAction="filter.clearAction"
          )
        
      .documents.col-12.col-md-9
        .repo-title.row.mx-0.mb-2.mb-md-5.mt-3.mt-md-0
          h1 REPOSITORIO
        // Mobile Filters
        .mobile-filters.row.mx-0.mb-5(v-if="mobile")
          button(@click="showMobileFilters = !showMobileFilters;" ref="showFilters")
            span Filtros 
            b-icon(icon="filter-circle" font-scale="1.4")
          .filters.col-12(v-if="showMobileFilters" ref="filters")
            .close-filters.row.d-flex.mx-0
              b-icon(icon="x-circle" @click="showMobileFilters = false")
            b-form-group.bold-font(
              v-for="filter in filters"
              :label="filter.label"
              :key="filter.id"
              :class="filter.customClass"
              v-slot="{ ariaDescribedby }"
            )
              b-form-checkbox.light-bold-font.mt-3(
                v-if="filter.inputType === 'checkbox-group'"
                v-for="child in filter.inputChilds"
                v-model="filter.model"
                @change="filterDocuments()"
                :value="child.key"
                :key="child.key"
                :class="child.key"
                v-bind:style="{ color: child.labelColor }"
                :aria-describedby="ariaDescribedby"
              ) {{ child.value }}
              autocomplete(
                v-if="filter.inputType === 'autocomplete'"
                :filterId="filter.id"
                :items="filter.items ? filter.items : states"
                :value="filter.model"
                :text="filter.optionsValue"
                :action="filter.action",
                :clearAction="filter.clearAction"
              )
        .grid.row.mx-0.mb-5
          .no-documents.pt-0.pt-md-5(v-if="docsByPage.length === 0")
            h4 No hay documentos
          .document-view.mb-3.mx-3(v-for="documentInfo in docsByPage")
            .type.row.mx-0.pt-1
              p.p-1.mt-3.mb-0(
                v-bind:style="{ backgroundColor: documentInfo.color }"
              ) {{ documentInfo.nametype.toUpperCase() }}
            .info.row.mx-0
              p.bold-font.mb-0 {{ documentInfo.title }}
              p.italic.mb-0 {{ documentInfo.subtitle }}
            .action.row.mx-0
              .line.px-5
              button.bold-font(
                @click="downloadDocument(documentInfo)"
                v-bind:style="{ color: documentInfo.color }"
              ) Ir a documento

        .pagination.row.mx-0
          nav(aria-label="pagination")
            ul.pagination
              li.page-item
                button.page-link.left-page(
                  @click="previousPage"
                  :disabled="currentPage === 1"
                )
                  b-icon(icon="caret-left-fill")
              li.page-item(v-for="page in totalPages")
                button.page-link(
                  @click="changePage(page)"
                  :class="{ active: page === currentPage }"
                ) {{ page }}
              li.page-item
                button.page-link.right-page(
                  @click="nextPage"
                  :disabled="currentPage === totalPages"
                )
                  b-icon(icon="caret-right-fill")

</template>

<script>
import autocomplete from "@/components/Autocomplete";

export default {
  name: "Repository",
  components: {
    autocomplete,
  },
  data() {
    return {
      filteredDocuments: [],
      showMobileFilters: false,
      // Pagination
      docsByPage: "",
      numPages: 0,
      currentPage: 1,
      showLoading: false,
    };
  },
  computed: {
    mobile() {
      return this.$store.state.mobile;
    },
    states() {
      let filterStates = this.$store.state.entities.filter(
        (e) => e.id_mun === "NA"
      );
      filterStates.unshift({
        full_name: "Federal",
        id_ent: "00",
        id_mun: "00",
        name_ent: "NA",
        name_mun: "NA",
      });
      filterStates.unshift({
        full_name: "Sin Entidad",
        id_ent: "NA",
        id_mun: "NA",
        name_ent: "NA",
        name_mun: "NA",
      });
      return filterStates;
    },
    filterDocumentEntitiesVal() {
      return this.$store.state.filterDocumentEntitiesVal;
    },
    filters() {
      return this.$store.state.filters;
    },
    // Max number of documents to show on grid
    numDocs() {
      return this.$store.state.mobile ? 4 : 12;
    },
    totalPages() {
      return this.numPages;
    },
  },
  methods: {
    /* 
      WARNING: This function just works with empty arrays, check later
      for compatibility with other values
    */
    filterDocuments() {
      this.filteredDocuments = this.$store.state.documents;
      // Check if there are any filters active and return them
      let filtersActive = this.$store.state.filters.filter((f) => {
        return f.model.length !== 0;
      });

      if (filtersActive.length > 0) {
        // Iterate over all active filters and apply filtering
        filtersActive.forEach((fa) => {
          this.filteredDocuments = this.filteredDocuments.filter((d) => {
            if (Array.isArray(fa.model)) {
              return fa.model.indexOf(d[fa.filterKey]) != -1;
            }
            return d[fa.filterKey] === fa.model;
          });
        });
      }
      /*
        Calculate total pages considering the total of documents and the max number
        of documents to display
      */
      this.numPages = Math.ceil(this.filteredDocuments.length / this.numDocs);
      this.currentPage = this.numPages <= 1 ? 1 : this.currentPage;
      // Calulate total of documents to be displayed
      let docsRange = this.getRangeForFilteredDocs(
        this.currentPage,
        this.numDocs
      );
      // Number of documents to display by page
      this.docsByPage = this.filteredDocuments.slice(
        docsRange.lower,
        docsRange.upper
      );
    },
    getRangeForFilteredDocs(page, numDocuments) {
      let upperDocDivision = page * numDocuments;
      let lowestDocDivision = page * numDocuments - numDocuments;
      return {
        upper: upperDocDivision,
        lower: lowestDocDivision,
      };
    },
    nextPage() {
      this.currentPage = this.currentPage + 1;
      this.filterDocuments();
    },
    previousPage() {
      this.currentPage = this.currentPage - 1;
      this.filterDocuments();
    },
    changePage(pageNumber) {
      if (pageNumber !== this.currentPage) {
        this.currentPage = pageNumber;
        this.filterDocuments();
      }
    },
    async downloadDocument(documentInfo) {
      this.showLoading = true;
      try {
        let response = await this.$store.dispatch(
          "getDocument",
          documentInfo.id
        );

        const base64ToBlob = (base64String, contentType = "") => {
          const byteCharacters = atob(base64String);
          const byteArrays = [];

          for (let i = 0; i < byteCharacters.length; i++) {
            byteArrays.push(byteCharacters.charCodeAt(i));
          }

          const byteArray = new Uint8Array(byteArrays);
          return new Blob([byteArray], { type: contentType });
        };
        // Creating dynamic link with event for download
        const linkDocument = document.createElement("a");
        const blob = base64ToBlob(
          response.data[0].document,
          documentInfo.datatype
        );

        const url = window.URL.createObjectURL(blob);
        linkDocument.setAttribute("href", url);
        linkDocument.setAttribute("target", "_blank");
        document.body.appendChild(linkDocument);
        linkDocument.click();
        this.showLoading = false;
      } catch (err) {
        console.log(err);
        this.showLoading = false;
      }
    },
    handleClickOutside(evt) {
      // Making sure the element ref exists before comparing
      if (this.$refs.filters) {
        if (
          !this.$refs.filters.contains(evt.target) &&
          !this.$refs.showFilters.contains(evt.target)
        ) {
          this.showMobileFilters = false;
        }
      }
    },
  },
  watch: {
    filters() {
      this.filterDocuments();
    },
  },
  // Calling data from created to improve performance
  async created() {
    this.showLoading = true;
    try {
      // Gets info for filters and saves it on store filters
      await this.$store.dispatch("initAllForms");

      let allDocumentsInfo = await this.$store.dispatch("getAllDocumentsInfo");
      this.$store.state.documents = allDocumentsInfo.data;

      let docsYears = allDocumentsInfo.data.map((d) => d.year);
      let docYearsReduced = [...new Set(docsYears)].map((y) => {
        // Format for filters proper working (value, key, labelColor)
        return {
          value: y,
          key: y,
          labelColor: "#ffffff",
        };
      });
      // Modifying filters json to include years data from documents
      this.$store.state.filters.forEach((filter) => {
        if (filter.filterKey === "year") {
          filter.inputChilds = docYearsReduced;
        }
      });
      this.showLoading = false;
      this.filterDocuments();
    } catch (err) {
      console.log(err);
      this.showLoading = false;
    }
  },
  mounted() {
    if (this.mobile) {
      document.addEventListener("click", this.handleClickOutside);
    }
  },
  destroyed() {
    if (this.mobile) {
      document.removeEventListener("click", this.handleClickOutside);
    }
  },
};
</script>

<style lang="scss">
@import "@/global.scss";
#repository {
  height: 100vh;
  .repo-container {
    height: 32em;
    background-color: $black;
  }
  .b-overlay-wrap {
    display: contents;
    width: 100%;
    height: 100%;
  }
  .filters {
    color: white;
    font-size: 14px;
    text-align: left;
    padding-top: 7.5em;
    padding-left: 7%;
    background-color: $black;
    .custom-control-label::after {
      background-color: $black;
      border: 1px solid white;
      border-radius: 3px;
    }
    .years-group {
      padding-left: 10px;
      margin-top: 35px !important;
    }
    .states-group {
      padding-left: 10px;
      margin-top: 35px !important;
    }
    #autocomplete {
      width: 80% !important;
      input {
        padding-right: 25px;
      }
    }
    .line {
      height: 470px;
      width: 2px;
      float: right;
      border-right: 1px solid #ffffff;
    }
  }
  .grid {
    min-height: 400px;
  }
  .no-documents {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .documents {
    background-color: $black;
    .pagination {
      justify-content: center;
      .page-link {
        border: none;
        color: rgba($color: #fff, $alpha: 0.7);
        font-weight: 700;
        background-color: $black;
        box-shadow: none;
        .b-icon {
          color: #fff;
          font-size: 20px;
        }
      }
      .page-link.active {
        color: #fff;
      }
      .page-item.active .page-link {
        background-color: white;
        color: #fff;
      }
      .left-page,
      .right-page {
        padding: 9px 10px;
      }
      .page-link:disabled .b-icon {
        color: rgba($color: #fff, $alpha: 0.3);
      }
    }
  }
  .document-view {
    width: 11.3em;
    height: 13em;
    background-color: #d8d8d8;
    border-radius: 8px;
    color: black;
    padding: 0px 10px;
    .type {
      display: block;
      p {
        cursor: default;
        font-size: 14px;
        width: fit-content;
        height: fit-content;
        margin: auto;
      }
    }
    .info {
      justify-content: center;
      flex-direction: column;
      min-height: 58%;
      p {
        font-size: 12px;
        cursor: default;
      }
      .italic {
        font-style: italic;
        font-size: 11px;
      }
    }
    .action {
      justify-content: center;
      .line {
        width: 0px;
        height: 1px;
        border-top: 1px solid black;
        margin: auto;
      }
      button {
        font-size: 13px;
        appearance: none;
        background: transparent;
        box-shadow: none;
        border: none;
      }
      a {
        font-size: 13px;
        cursor: pointer;
        text-decoration: none;
      }
    }
  }
}
@media (max-width: 767.98px) {
  #repository {
    .mobile-filters {
      display: flex;
      justify-content: flex-end;
      button {
        background-color: $black;
        color: white;
        border: none;
        box-shadow: none;
        span {
          vertical-align: text-bottom;
        }
      }
      .filters {
        padding-top: 1em;
        position: absolute;
        opacity: 0.85;
        top: 12%;
        width: 70%;
        border-radius: 5px;
        border: 1px solid white;
        z-index: 1;
      }
      .close-filters {
        justify-content: flex-end;
      }
    }
    .repo-title {
      justify-content: center;
    }
    .document-view {
      width: 17.3em;
      height: 12em;
      padding-bottom: 12px;
      .action {
        display: block;
      }
    }
  }
}
</style>
