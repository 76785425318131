<template lang="pug">
  #autocomplete
    input(
      type="text"
      @input="onChange"
      @click="isOpen = true"
      v-model="search"
    )
    b-icon(:icon="search === '' || !search ? 'search' : 'x-circle'" @click="clickIcon()")
    ul#autocomplete-results(v-show="isOpen")
      li.autocomplete-result(
        v-for="(result, i) in results"
        :key="i"
        @click="setResult(result)"
        :class="{ 'is-active': i === arrowCounter }"
      ) {{ result }}

</template>

<script>
export default {
  name: "autocomplete",
  props: {
    /*
      Identify wich filter has been used
      NOTE: Integration with store filtering in case of dynamic use
    */
    filterId: {
      type: String,
      required: false
    },
    // Array of items for option listing
    items: {
      type: Array,
      required: false
    },
    // Property name which has the text value to show on listing
    text: {
      type: String,
      required: false
    },
    // Value to show on input
    value: {
      type: String,
      required: false
    },
    /*
      Store action to execute on input change
      Note: It is necesary to define the action on the store to execute it
    */
    action: {
      type: String,
      required: false
    },
    /*
      Store action to execute on clear input
      Note: It is necesary to define the action on the store to execute it
    */
    clearAction: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      isOpen: false,
      search: "",
      results: [],
      arrowCounter: 0
    };
  },
  computed: {
    valueChange() {
      return this.value;
    }
  },
  methods: {
    onChange() {
      this.$emit("input", this.search);
      this.filterResults();
      this.isOpen = true;
    },
    filterResults() {
      let exploreItems = this.identifyItemsText(this.items);
      let normalizedSearch = this.search
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");

      this.results = exploreItems.filter(item => {
        let normalizedItem = item
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");
        return (
          normalizedItem.toLowerCase().indexOf(normalizedSearch.toLowerCase()) >
          -1
        );
      });
    },
    setResult(result) {
      let dispatchParams = {
        value: "",
        textParam: this.text ? this.text : null,
        filterId: this.filterId ? this.filterId : null
      };
      // IF text is defined return whole object in store action
      if (this.action && this.text) {
        let resultItem = this.items.find(item => {
          return item[this.text] === result;
        });
        dispatchParams.value = resultItem;
        this.$store.dispatch(this.action, dispatchParams);
        // If it is just an action just return the result
      } else if (this.action) {
        dispatchParams.value = result;
        this.$store.dispatch(this.action, dispatchParams);
      }
      this.search = result;
      this.isOpen = false;
    },
    identifyItemsText(itemsText) {
      if (this.text) {
        return itemsText.map(i => {
          return i[this.text];
        });
      } else {
        return itemsText;
      }
    },
    clickIcon() {
      // There is no selection, show items list
      if (this.search === "" || !this.search) {
        this.isOpen = true;
      } else if (this.clearAction) {
        // Clear selection dispatching selected action
        let dispatchParams = {
          value: "",
          textParam: this.text ? this.text : null,
          filterId: this.filterId ? this.filterId : null
        };
        this.$store.dispatch(this.clearAction, dispatchParams);
      } else {
        // Just clear the autocomplete value
        this.search = "";
      }
    },
    handleClickOutside(evt) {
      if (!this.$el.contains(evt.target)) {
        this.isOpen = false;
        this.arrowCounter = -1;
      }
    }
  },
  watch: {
    items: function(newItems, oldItems) {
      if (newItems.length !== oldItems.length) {
        this.results = this.identifyItemsText(newItems);
      }
    },
    valueChange: function(selection) {
      this.search = selection;
    }
  },
  mounted() {
    this.search = this.value;
    this.results = this.identifyItemsText(this.items);
    document.addEventListener("click", this.handleClickOutside);
  },
  destroyed() {
    document.removeEventListener("click", this.handleClickOutside);
  }
};
</script>

<style lang="scss">
#autocomplete {
  position: relative;
  background: #191a1a;
  input {
    background-color: #191a1a;
    border: 1px solid white;
    border-radius: 5px;
    padding-left: 12px;
    width: 100%;
    color: white;
    text-overflow: ellipsis;
  }
  .b-icon {
    position: absolute;
    bottom: 7px;
    right: 10px;
    font-weight: 700;
    cursor: pointer;
  }
  #autocomplete-results {
    padding: 0;
    margin: 0;
    border: 1px solid #eeeeee;
    max-height: 170px;
    overflow: auto;
    width: 100%;
    position: absolute;
    border-radius: 6px;
    z-index: 2000;
  }
  .autocomplete-result {
    list-style: none;
    text-align: left;
    padding: 4px 8px;
    cursor: pointer;
    background-color: rgba(#d8d8d8, 0.76);
    color: black;
    font-weight: 500;
    border-bottom: 1px solid white;
  }
  .autocomplete-result.is-active,
  .autocomplete-result:hover {
    background-color: rgba(#d8d8d8, 1);
  }
}
</style>
