import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

const requireComponent = require.context(
  `../../public/assets/jsons`,
  false,
  /.(json)$/
);

export default new Vuex.Store({
  state: {
    entities: [],
    selectedEntity: "",
    mapData: "",
    entityData: "",
    initialYears: [2006, 2023],
    filterYears: [],
    autocompleteVal: "",
    filteredMapData: "",
    documents: "",
    filters: "",
    mobile: false,
    host: "https://plataformaciudadanadefosas.org/api/v1",
  },
  mutations: {},
  actions: {
    getMapData({ state }, yearRange) {
      return axios.post(`${state.host}/map`, {
        years: yearRange,
      });
    },
    getScatterData({ state }) {
      return axios.get(`${state.host}/scatter`);
    },
    async getEntityData({ state }, selection) {
      if (selection.value) {
        state.selectedEntity = selection.value;
        state.autocompleteVal =
          typeof selection.value === "string"
            ? selection.value
            : selection.value[selection.textParam];
      } else {
        state.selectedEntity = selection;
        state.autocompleteVal = selection.full_name;
      }

      state.entityData = await axios.post(`${state.host}/entity`, {
        id_ent: Number(state.selectedEntity.id_ent),
        id_mun:
          state.selectedEntity.id_mun === "NA"
            ? null
            : Number(state.selectedEntity.id_mun),
        years: state.filterYears,
      });
    },
    getAllDocumentsInfo({ state }) {
      return axios.get(`${state.host}/getDocumentsInfo`);
    },
    getDocument({ state }, docInfo) {
      return axios.put(`${state.host}/getDocument`, {
        id: docInfo,
      });
    },
    filterDocByState({ state }, selection) {
      // Find selected filter on state object, change model and replace it for watch action
      let filterToChange = state.filters.find(
        (f) => f.id === selection.filterId
      );
      filterToChange.model =
        typeof selection.value === "string"
          ? selection.value
          : selection.value[selection.textParam];
      state.filters = state.filters.map((nf) => {
        if (nf.id === selection.filterId) {
          nf = filterToChange;
        }
        return nf;
      });
    },
    clearDocStateFilter({ state }, selection) {
      // Find selected filter on state object, change model and replace it for watch action
      let filterToChange = state.filters.find(
        (f) => f.id === selection.filterId
      );
      filterToChange.model = selection.value;
      state.filters = state.filters.map((nf) => {
        if (nf.id === selection.filterId) {
          nf = filterToChange;
        }
        return nf;
      });
    },
    async initAllForms({ state }) {
      return new Promise((resolve) => {
        requireComponent.keys().forEach(async (fileName, i) => {
          let axiosFile = fileName.replace(/^.\//, "");
          let jsonResponse = await axios.get(
            `${window.location.origin}/assets/jsons/${axiosFile}`
          );
          let stateVariable = axiosFile.replace(/\.\w+$/, "");
          state[stateVariable] = jsonResponse.data;
          if (i === requireComponent.keys().length - 1) {
            return resolve("ok");
          }
        });
      });
    },
  },
});
