  import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Methodology from "../views/Methodology.vue";
import Repository from "../views/Repository.vue";
import store from "../store/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/tablero",
    name: "Methodology",
    component: Methodology
  },
  {
    path: "/repositorio",
    name: "Repo",
    component: Repository
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  // Init map on route change
  store.state.selectedEntity = "";
  store.state.filterYears = store.state.initialYears;
  store.state.autocompleteVal = "";
  next();
});

export default router;
