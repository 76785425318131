<template lang="pug">
  #navbar
    // Mobile Menu
    .row.header-mobile.d-flex.py-2.mx-0(v-if="mobile")
      .logos.col-9.d-flex.py-2.pr-0(v-if="currentRoute === 'Home'")
        .row.mx-0
          .logo-container.col-3.px-0.m-auto
            img.img-fluid(alt="art19" src="@/assets/logos/art19_logo_blanco.png")
          .logo-container.col-5.px-1.m-auto
            img.img-fluid(alt="dgfii_pdh" src="@/assets/logos/LOGO-DGFII_PDH.png")
          .logo-container.col-5.px-1.m-auto
            img.img-fluid(alt="datacivica" src="@/assets/logos/Logo DC_Blanco.png")
          .logo-container.col-4.px-1.m-auto
            img.img-fluid(alt="hrdag" src="@/assets/logos/hrdag_logo.png")
          .logo-container.col-3.px-1.m-auto
            img.img-fluid(alt="fondo-canada" src="@/assets/logos/Fondo-Canada-Blanco-SF.png")
      .logo.pl-3.mx-0(v-else, @click="goToRoute('Home')")
        p.light-font.m-0 PLATAFORMA
        p.bold-font.m-0 CIUDADANA DE FOSAS
      b-icon-list.m-3.h3(@click="showMenu()")
      ul.mobile-menu(v-if="mobileMenu")
        li
          button(@click="goToRoute('Home')")
            p.light-bold-font.m-0 Inicio
        li
          button(@click="goToRoute('Methodology')")
            p.light-bold-font.m-0 Tablero
        li
          a(href="/assets/METODOLOGIA.pdf" target="_blank")
            button
              p.light-bold-font.m-0 Metodología
        li
          button(@click="goToRoute('Repo')")
            p.light-bold-font.m-0 Repositorio
    // Desktop Menu
    .row.header.mx-0(v-else)
      .logos.py-2.px-4(@click="goToRoute('Home')" v-if="currentRoute === 'Home'")
        .logo-container.small.pr-3
          img.img-fluid(alt="art19" src="@/assets/logos/art19_logo_blanco.png")
        .logo-container.large.mr-4
          img.img-fluid(alt="dgfii_pdh" src="@/assets/logos/LOGO-DGFII_PDH.png")
        .logo-container.large.mr-4
          img.img-fluid(alt="datacivica" src="@/assets/logos/Logo DC_Blanco.png")
        .logo-container.small.mr-3
          img.img-fluid(alt="hrdag" src="@/assets/logos/hrdag_logo.png")
        .logo-container.small
          img.img-fluid(alt="fondo-canada" src="@/assets/logos/Fondo-Canada-Blanco-SF.png")
      .logo.pl-3.mx-0(v-else, @click="goToRoute('Home')")
        p.light-font.m-0 PLATAFORMA
        p.bold-font.m-0 CIUDADANA DE FOSAS
      .tabs.d-flex
        .tab.break-line.pl-4.mr-2
          button(@click="goToRoute('Home')")
            p.light-font.m-0 Inicio
        .tab.mr-1
          button(@click="goToRoute('Methodology')")
            p.light-font.m-0 Tablero
        .tab.mr-1
          a(href="/assets/METODOLOGIA.pdf" target="_blank")
            button
              p.light-font.m-0 Metodología
        .tab
          button(@click="goToRoute('Repo')")
            p.light-font.m-0 Repositorio

</template>

<script>
export default {
  name: "Navbar",
  data() {
    return {
      mobileMenu: false,
      currentRoute: "Home",
    };
  },
  computed: {
    mobile() {
      return this.$store.state.mobile;
    },
  },
  methods: {
    goToRoute(route) {
      this.mobileMenu = false;
      if (this.$route.name !== route) {
        this.$router.push({ name: route });
      }
    },
    showMenu() {
      this.mobileMenu = !this.mobileMenu;
    },
    handleClickOutside(evt) {
      if (!this.$el.contains(evt.target)) {
        this.mobileMenu = false;
      }
    },
  },
  watch: {
    $route(to) {
      this.currentRoute = to.name;
    },
  },
  mounted() {
    this.currentRoute = this.$route.name;
    if (this.mobile) {
      document.addEventListener("click", this.handleClickOutside);
    }
  },
  destroyed() {
    if (this.mobile) {
      document.removeEventListener("click", this.handleClickOutside);
    }
  },
};
</script>

<style lang="scss">
@import "@/global.scss";
#navbar {
  .header {
    height: 145px;
    justify-content: space-between;
    .logos {
      width: 65%;
      max-height: 170px;
      display: flex;
      cursor: pointer;
      .logo-container {
        max-height: 165px;
        align-self: center;
        img {
          max-height: 100%;
        }
      }
      .small {
        width: 12%;
      }
      .medium {
        width: 17%;
      }
      .large {
        width: 22%;
      }
    }
    .logo {
      margin: 0px;
      justify-content: center;
      align-self: center;
      cursor: pointer;
      p {
        font-size: 18px;
      }
      p.bold-font {
        color: $black;
        background-color: #e0e0e0;
        width: fit-content;
        margin: auto;
        padding: 0px 5px;
      }
    }
    .tabs {
      width: 35%;
      justify-content: center;
      align-items: center;
      .tab {
        height: 45px;
        display: flex;
        align-items: center;
        button {
          background-color: $black;
          border: none;
          &:focus {
            outline: none;
          }
        }
        p {
          cursor: pointer;
          font-size: 15px;
          color: $white;
        }
      }
      .break-line {
        border-left: 1px solid $white;
      }
    }
  }
}
@media (max-width: 767.98px) {
  #navbar {
    .header-mobile {
      justify-content: space-between;
      align-items: center;
    }
    .logo {
      p {
        font-size: 12px !important;
      }
      p.bold-font {
        color: $black;
        background-color: #e0e0e0;
        width: fit-content;
        margin: auto;
        padding: 0px 5px;
      }
    }
    .mobile-menu {
      list-style-type: none;
      display: block;
      position: absolute;
      top: 45px;
      right: 15px;
      border-radius: 5px;
      z-index: 1;
      li {
        background-color: rgba(224, 224, 224, 0.9);
        padding: 15px;
        border-bottom: 1px solid white;
      }
      button {
        color: $black;
        background-color: transparent;
        border: none;
        font-size: 17px;
        &:focus {
          outline: none;
        }
      }
    }
  }
}
</style>
