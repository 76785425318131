<template lang="pug">
  #method.pt-5
    .row.tools.mx-3.mx-md-5.pb-3
      .col-12.col-md-2.block.px-0.mb-3
        p.bold-font.m-0 ¿Quién ha reportado
        p.bold-font el hallazgo de fosas?
        button.clean(@click="cleanMap()" v-if="!mobile") Limpiar todo
      .col-12.col-md-3.block.venn-container
        .row.federal-text.mx-0
          p.venn-text.mb-0 FISCALÍA FEDERAL
        .row.venn.mx-0
          .col-3.centered.p-0
            p.venn-text.mb-2 PRENSA
          .col-6.p-0
            svg(version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="180 100 590 850" style="enable-background:new 0 0 900 900;" xml:space="preserve")
              g#fiscalia_federal
                path(source=3 style="fill:#7473FF;stroke:#191A1A;stroke-width:16;stroke-miterlimit:10;" d="M458.9,349c28.4-16.5,61.3-25.9,96.5-26c35.6-0.1,68.9,9.5,97.6,26.3c-0.5-106.4-86.9-192.3-193.3-192.2c-106,0.2-191.9,85.8-192.7,191.6c28.1-16.1,60.7-25.3,95.4-25.4C397.5,323.2,430.4,332.6,458.9,349z")
              g#fiscalia_estatal
                path(source=2 style="fill:#12B2AA;stroke:#191A1A;stroke-width:16;stroke-miterlimit:10;" d="M652.9,349.3c0,0.2,0,0.4,0,0.5c0.1,71.9-39.1,134.6-97.3,168c-0.5,70.7-39.1,132.4-96.2,165.6c28.4,16.4,61.4,25.7,96.6,25.7c106.6-0.2,192.9-86.7,192.7-193.3C748.6,444.7,710.1,382.7,652.9,349.3z")
              g#prensa
                path(source=1 style="fill:#FFBB5E;stroke:#191A1A;stroke-width:16;stroke-miterlimit:10;" d="M362.6,516.9c-57.2-33.4-95.6-95.4-95.8-166.4c0-0.6,0-1.2,0-1.8c-58.2,33.3-97.4,96.1-97.3,168c0.2,106.6,86.7,192.9,193.3,192.7c35.2-0.1,68.1-9.5,96.5-26C401.8,650.2,362.9,588.1,362.6,516.9z")
              g#intersection1
                path(source=4 style="fill:#8BB784;stroke:#191A1A;stroke-width:16;stroke-miterlimit:10;" d="M555.6,517.8c-28.1,16.1-60.7,25.3-95.4,25.4c-35.6,0.1-68.9-9.5-97.6-26.3c0.3,71.2,39.2,133.3,96.8,166.5C516.6,650.2,555.1,588.5,555.6,517.8z")
              g#intersection2
                path(source=5 style="fill:#B494B5;stroke:#191A1A;stroke-width:16;stroke-miterlimit:10;" d="M362.6,516.9c0-0.2,0-0.4,0-0.5c-0.1-71.4,38.6-133.9,96.2-167.4c-28.4-16.4-61.4-25.7-96.6-25.7c-34.7,0.1-67.3,9.3-95.4,25.4c0,0.6,0,1.2,0,1.8C267,421.4,305.5,483.4,362.6,516.9z")
              g#intersection3
                path(source=6 style="fill:#4493D5;stroke:#191A1A;stroke-width:16;stroke-miterlimit:10;" d="M458.9,349c57.7,33.3,96.7,95.6,96.8,167c0,0.6,0,1.2,0,1.8c58.2-33.3,97.4-96.1,97.3-168c0-0.2,0-0.4,0-0.5c-28.7-16.8-62-26.3-97.6-26.3C520.2,323,487.2,332.5,458.9,349z")
              g#todos
                path(source=7 style="fill:#E5E3E3;stroke:#191A1A;stroke-width:16;stroke-miterlimit:10;" d="M458.9,349c-57.6,33.5-96.4,95.9-96.2,167.4c0,0.2,0,0.4,0,0.5c28.7,16.8,62,26.3,97.6,26.3c34.7-0.1,67.3-9.3,95.4-25.4c0-0.6,0-1.2,0-1.8C555.5,444.6,516.6,382.2,458.9,349z")
            .venn-hovers
              .prensa-hover.p-2
                p.light-bold-font.mb-0 Municipios con reportes solo de Prensa
              .fiscalia_federal-hover.p-2
                p.light-bold-font.mb-0 Municipios con reportes solo de Fiscalía Federal
              .fiscalia_estatal-hover.p-2
                p.light-bold-font.mb-0 Municipios con reportes solo de Fiscalía Estatal
              .todos-hover.p-2
                p.light-bold-font.mb-0 Municipios con reportes de todas las fuentes
              .intersection1-hover.p-2
                p.light-bold-font.mb-0 Municipios con reportes de Prensa y Fiscalía Estatal
              .intersection2-hover.p-2
                p.light-bold-font.mb-0 Municipios con reportes de Prensa y Fiscalía Federal
              .intersection3-hover.p-2
                p.light-bold-font.mb-0 Municipios con reportes de Fiscalía Estatal y Fiscalía Federal

          .col-3.centered.p-0
            p.venn-text FISCALÍA ESTATAL

      .select.col-12.col-md-3.block.my-4.px-4
        p.bold-font.mb-1 Buscar estado o municipio
        autocomplete(:items="this.entities" :value="autocompleteVal" text="full_name" action="getEntityData")
      .col-12.filters-info.d-flex.px-0(v-if="mobile")
        .info
          p.mb-0 Entidad: {{autocompleteVal !== "" ? autocompleteVal : "Nacional"}}
          p.mb-0 Años: {{this.$store.state.filterYears[0]}} - {{this.$store.state.filterYears[1]}}
        .filters-btn(ref="filtersBtn")
          button(@click="openMoreFiltersMenu()" ref="showFilters")
            span Filtros
            b-icon(icon="filter-circle" font-scale="1.4")
      .col-12.col-md-3.d-flex.more-filters.pb-md-3(
        v-if="showMoreFilters"
        v-bind:style="{ top: autocompleteVal !== '' ? '26%' : '43%' }"
        ref="moreFilters"
      )
        .close-filters.row.d-flex.mx-0(v-if="mobile")
          b-icon(icon="x-circle" @click="showMoreFilters = false")
        .slider-container.col-12.block.mb-4
          p.bold-font.mb-2 Año
          .row.slider-range.mx-0
            p.bold-font {{ this.$store.state.initialYears[0] }}
            p.bold-font {{ this.$store.state.initialYears[1] }}
          .slider(ref="slider")
        // Mobile button for cleaning filters
        .col-12.block.mt-4(v-if="mobile")
          button.clean(@click="cleanMap()") Limpiar todo

    .row.interactive.pl-md-5.pr-md-2.mx-0(
      v-bind:style="{ minHeight: mobile ? '300px' : '500px' }"
    )
      Map
    .row.downloads.d-flex.pr-md-5.pb-5.mx-0.mt-5
      a(href="/assets/METODOLOGIA.pdf" target="_blank")
        button.bold-font.mr-md-4 VER METODOLOGÍA
      form(method="get" action="/assets/bases_pcdf.zip")
        button.bold-font(type="submit") BASE DE DATOS

    Footer

</template>

<script>
import * as noUiSlider from "nouislider";
import "nouislider/distribute/nouislider.min.css";
import * as d3 from "d3";

import Map from "./Map";
import Footer from "@/components/Footer.vue";

import autocomplete from "@/components/Autocomplete";

export default {
  name: "Methodology",
  components: {
    Map,
    Footer,
    autocomplete,
  },
  data() {
    return {
      showMoreFilters: true,
      selectedSources: [1, 2, 3, 4, 5, 6, 7],
    };
  },
  computed: {
    mobile() {
      return this.$store.state.mobile;
    },
    entities() {
      return this.$store.state.entities;
    },
    autocompleteVal() {
      return this.$store.state.autocompleteVal;
    },
  },
  methods: {
    initializeVenn() {
      d3.selectAll(".venn svg g")
        .style("cursor", "pointer")
        .on("mouseover", event => {
          let idSelecttion = d3
            .select(`#${event.target.parentNode.id}`)
            .attr("id");

          d3.select(`.${idSelecttion}-hover`).style("display", "block");
        })
        .on("mouseout", event => {
          let idSelecttion = d3
            .select(`#${event.target.parentNode.id}`)
            .attr("id");

          d3.select(`.${idSelecttion}-hover`).style("display", "none");
        })
        .on("click", event => {
          let source = undefined;
          if (event.target.parentNode.classList[0] === "disabled-set") {
            d3.select(`#${event.target.parentNode.id}`).attr("class", "");
            source = Number(d3.select(event.target).attr("source"));
            this.selectedSources.push(source);

            this.$store.state.filteredMapData = this.$store.state.mapData.filter(
              d => this.selectedSources.includes(d.id_source)
            );
          } else {
            d3.select(`#${event.target.parentNode.id}`).attr(
              "class",
              "disabled-set"
            );
            source = Number(d3.select(event.target).attr("source"));
            this.selectedSources.splice(
              this.selectedSources.indexOf(source),
              1
            );

            this.$store.state.filteredMapData = this.$store.state.mapData.filter(
              d => this.selectedSources.includes(d.id_source)
            );
          }
        });
    },
    cleanMap() {
      let slider = this.$refs.slider;
      slider.noUiSlider.set(this.$store.state.initialYears, true);
      this.$store.state.filterYears = this.$store.state.initialYears;
      this.selectedSources = [1, 2, 3, 4, 5, 6, 7];
      d3.selectAll(".venn svg g").attr("class", "");
      this.$store.state.selectedEntity = "";
      this.$store.state.autocompleteVal = "";
    },
    openMoreFiltersMenu() {
      this.showMoreFilters = !this.showMoreFilters;
      if (this.showMoreFilters) {
        this.$nextTick(() => {
          this.initializeSlider();
        });
      }
    },
    initializeSlider() {
      let slider = this.$refs.slider;

      noUiSlider.create(slider, {
        start: this.$store.state.filterYears,
        behaviour: "tap",
        connect: true,
        tooltips: true,
        format: {
          to: value => {
            return value.toFixed(0);
          },
          from: value => {
            return value;
          },
        },
        range: {
          min: [this.$store.state.initialYears[0]],
          max: [this.$store.state.initialYears[1]],
        },
      });

      let mapData = this.$store.state.mapData;

      slider.noUiSlider.on("set", async years => {
        let intYears = years.map(y => Number(y));
        this.$store.state.filterYears = intYears;
        mapData = await this.$store.dispatch("getMapData", intYears);
        this.$store.state.mapData = mapData.data;
        this.$store.state.filteredMapData = mapData.data.filter(d =>
          this.selectedSources.includes(d.id_source)
        );
        if (this.$store.state.selectedEntity !== "") {
          this.$store.dispatch(
            "getEntityData",
            this.$store.state.selectedEntity
          );
        }
      });
    },
    handleClickOutside(evt) {
      // Making sure the element ref exists before comparing
      if (this.$refs.moreFilters) {
        if (
          !this.$refs.moreFilters.contains(evt.target) &&
          !this.$refs.filtersBtn.contains(evt.target)
        ) {
          this.showMoreFilters = false;
        }
      }
    },
  },
  async created() {
    let mapData = await this.$store.dispatch("getMapData", [2006, 2023]);
    this.$store.state.mapData = mapData.data;
    this.$store.state.filteredMapData = mapData.data;
    this.$store.state.filterYears = this.$store.state.initialYears;
  },
  mounted() {
    if (this.mobile) {
      document.addEventListener("click", this.handleClickOutside);
      this.showMoreFilters = false;
    }
    this.initializeVenn();
    this.initializeSlider();
  },
  destroyed() {
    if (this.mobile) {
      document.removeEventListener("click", this.handleClickOutside);
    }
  },
};
</script>

<style lang="scss">
@import "@/global.scss";
#method {
  .tools {
    display: flex;
    justify-content: center;
    border-bottom: solid 1px #979797;
    .block {
      align-self: flex-end;
    }
    .clean {
      -webkit-appearance: none;
      -moz-appearance: none;
      border: 1px solid $white;
      border-radius: 7px;
      background-color: $black;
      color: $white;
      font-size: 12px;
      padding: 7px 20px;
    }
    .federal-text {
      display: flex;
      justify-content: center;
      .venn-text {
        margin-right: 5%;
        width: 100% !important;
      }
    }
    .venn {
      min-width: 150px;
      min-height: 90px;
      max-height: 120px;
      svg {
        min-width: inherit;
        height: fit-content;
        width: 60%;
      }
      .disabled-set {
        opacity: 0.3;
      }
      .centered {
        align-self: center;
      }
      .venn-hovers > div {
        display: none;
        position: absolute;
        color: $black;
        font-size: 11px;
        width: 170px;
        border-radius: 3px;
        background-color: rgba($color: white, $alpha: 0.82);
      }
      .prensa-hover,
      .fiscalia_estatal-hover {
        top: -20px;
      }
      .fiscalia_federal-hover {
        top: 45px;
      }
      .todos-hover {
        top: 5px;
      }
      .intersection1-hover {
        top: 10px;
      }
      .intersection2-hover,
      .intersection3-hover {
        top: 70px;
      }
    }
    .venn-text {
      color: white;
      font-size: 14px;
      align-self: flex-end;
      width: 5em;
    }
    .select p {
      text-align: left;
    }
    .slider-container {
      p {
        text-align: left;
      }
    }
    .slider {
      min-width: 200px;
    }
    .slider-range {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      p:first-child {
        margin-left: -15px;
      }
      p:nth-child(2) {
        margin-right: -20px;
      }
    }
  }
  .interactive {
    width: 100%;
    min-height: 500px;
  }
  .downloads {
    justify-content: flex-end;
    button {
      -webkit-appearance: none;
      -moz-appearance: none;
      border-radius: 9px;
      border: none;
      background-color: #d8d8d8;
      color: $black;
      font-size: 12px;
      padding: 7px 20px;
    }
  }
  .noUi-horizontal {
    height: 8px;
  }
  .noUi-target {
    background: #4f4f4f;
    border: none;
    box-shadow: none;
  }
  .noUi-connect {
    background: #bfbfbf;
    height: 8px;
  }
  .noUi-handle {
    width: 18px;
    height: 18px;
    border: none;
    border-radius: 50%;
  }
  .noUi-handle::before,
  .noUi-handle::after {
    content: none;
  }
  .noUi-tooltip {
    bottom: -150%;
    background-color: transparent;
    color: $white;
    border: none;
    font-size: 12px;
  }
}
@media (max-width: 767.98px) {
  #method {
    .filters-info {
      justify-content: space-between;
      align-items: center;
      .info {
        text-align: left;
        font-size: 12px;
        max-width: 60%;
      }
      .filters-btn button {
        background-color: $black;
        color: white;
        border: none;
        box-shadow: none;
        border-radius: 5px;
        span {
          vertical-align: text-bottom;
        }
      }
    }
    .more-filters {
      flex-direction: column;
      position: absolute;
      z-index: 401;
      background-color: rgba($color: $black, $alpha: 0.8);
      width: 80%;
      right: 0px;
      padding: 15px;
      border: 1px solid white;
      border-radius: 5px;
      .block {
        margin-bottom: 25px !important;
      }
    }
    .close-filters {
      justify-content: flex-end;
    }
    .select p {
      text-align: center !important;
    }
    .slider {
      min-width: 100px !important;
    }
    .slider-container {
      p {
        text-align: center !important;
      }
    }
    .downloads {
      justify-content: space-between;
      padding: 10px;
    }
  }
}
</style>
