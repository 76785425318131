<template lang="pug">
  #scatter(ref="scatterWindow")
    #header.row.d-flex.mx-0
      .triangle(@mousedown="resizeWindow")
      .col-10.header-text.mt-2.mr-3(@mousedown="dragWindow")
        p.mb-0.bold-font Probabilidad de nuevos hallazgos
        p.mb-0 Última estimación con datos 2018
          b-icon#info.ml-2(icon="info-circle-fill")
          b-tooltip.tool(target="info" triggers="hover" :popover-style="{background: '#ffffff'}") Esta estimación mide la probabilidad
            |  de encontrar nuevas fosas en cada municipio. La estimación más reciente se
            |  generó con datos del 2018. Para más información, revisa nuestra nota
            |  metodológica.

    #scatter-graph.row.mx-0.p-2(ref="scatter")

</template>

<script>
import * as d3 from "d3";

export default {
  name: "Scatter",
  data() {
    return {
      data: "",
      positions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0
      },
      elemX: 0,
      elemY: 0,
      windowWidth: 0,
      windowHeight: 0
    };
  },
  computed: {
    selectedEntity() {
      return this.$store.state.selectedEntity;
    }
  },
  methods: {
    drawScatter() {
      d3.select("#scatter-graph svg").remove();

      let margin = { top: 5, right: 40, bottom: 60, left: 45 };
      let width = this.$refs.scatter.clientWidth - margin.left - margin.right;
      let height = this.$refs.scatter.clientHeight - margin.top - margin.bottom;

      let svg = d3
        .select("#scatter-graph")
        .append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

      let x = d3
        .scaleLinear()
        .domain(d3.extent(this.data.data, d => Number(d.prensa_pred)))
        .range([0, width]);

      let y = d3
        .scaleLinear()
        .domain(d3.extent(this.data.data, d => Number(d.fiscalia_pred)))
        .range([height, 0]);

      let xAxis = d3.axisBottom(x).tickValues([0, 1]);
      let yAxis = d3.axisLeft(y).tickValues([0, 1]);

      svg
        .append("g")
        .attr("class", "x axis")
        .attr("transform", `translate(0, ${height})`)
        .call(xAxis)
        .append("text")
        .attr("class", "label")
        .attr("x", width / 2 + 20)
        .attr("y", 30)
        .style("text-anchor", "end")
        .text("Prensa")
        .attr("fill", "white");

      svg
        .append("g")
        .attr("class", "y axis")
        .call(yAxis)
        .append("text")
        .attr("class", "label")
        .attr("transform", "rotate(-90)")
        .attr("y", -8)
        .attr("x", -height / 2 + 70)
        .attr("dy", "-1.5em")
        .style("text-anchor", "end")
        .text("Fiscalias Locales")
        .attr("fill", "white");

      svg
        .selectAll(".dot")
        .data(this.data.data)
        .enter()
        .append("circle")
        .attr("class", "dot")
        .attr("r", 3.5)
        .attr("fill", "#979797")
        .attr("opacity", 0.7)
        .attr("stroke", "#121212")
        .attr("cx", d => x(d.prensa_pred))
        .attr("cy", d => y(d.fiscalia_pred));

      // Linear Regression

      let yVal = this.data.data.map(d => parseFloat(d.prensa_pred));
      let xVal = this.data.data.map(d => parseFloat(d.fiscalia_pred));

      let line = this.linearRegression(xVal, yVal);

      let max = d3.max(this.data.data, d => d.prensa_pred);

      svg
        .append("svg:line")
        .attr("x1", x(0))
        .attr("y1", y(line.intercept))
        .attr("x2", x(max))
        .attr("y2", y(max * line.slope + line.intercept))
        .attr("stroke", "#979797")
        .attr("stroke-width", 2.5)
        .attr("stroke-dasharray", 5);

      this.highlightMuns();
    },
    linearRegression(x, y) {
      // Aplying linear regression equation to data and getting points for line creation on svg
      let result = {};
      let n = y.length;
      let sum_x = 0;
      let sum_y = 0;
      let sum_xy = 0;
      let sum_xx = 0;
      let sum_yy = 0;

      for (let i = 0; i < y.length; i++) {
        sum_x += x[i];
        sum_y += y[i];
        sum_xy += x[i] * y[i];
        sum_xx += x[i] * x[i];
        sum_yy += y[i] * y[i];
      }

      result["slope"] =
        (n * sum_xy - sum_x * sum_y) / (n * sum_xx - sum_x * sum_x);
      result["intercept"] = (sum_y - result.slope * sum_x) / n;
      result["r2"] = Math.pow(
        (n * sum_xy - sum_x * sum_y) /
          Math.sqrt(
            (n * sum_xx - sum_x * sum_x) * (n * sum_yy - sum_y * sum_y)
          ),
        2
      );
      return result;
    },
    highlightMuns() {
      const tooltip = d3
        .select("#scatter-graph")
        .append("div")
        .attr("class", "scatter-tooltip")
        .style("display", "none");

      // Raising selected entity Municipality or Municipalities
      d3.selectAll("svg circle")
        .attr("fill", "#979797")
        .attr("r", 3.5)
        .attr("opacity", 0.7)
        .attr("stroke", "#121212")
        .each((d, i, e) => {
          // If it's state fill al muns
          if (
            this.selectedEntity.id_mun === "NA" &&
            d.id_ent === Number(this.selectedEntity.id_ent)
          ) {
            d3.select(e[i])
              .attr("fill", "white")
              .raise()
              .attr("r", 5)
              .attr("opacity", 1)
              .attr("stroke", "#121212")
              .on("mouseover", (e, d) => {
                tooltip
                  .transition()
                  .duration(200)
                  .style("display", "block");
                tooltip
                  .html(
                    `${d.name_mun}<br/>Probabilidad Prensa: ${d.prensa_pred}<br/>Probabilidad Fiscalía: ${d.fiscalia_pred}`
                  )
                  .style("left", `${e.offsetX - 150}px`)
                  .style("top", `${e.offsetY}px`);
              })
              .on("mouseout", () => {
                tooltip
                  .transition()
                  .duration(500)
                  .style("display", "none");
              });
            // If it's mun just highlight the selection
          } else if (
            d.id_ent === Number(this.selectedEntity.id_ent) &&
            d.name_mun === this.selectedEntity.name_mun
          ) {
            d3.select(e[i])
              .attr("fill", "white")
              .raise()
              .attr("r", 5)
              .attr("opacity", 1)
              .attr("stroke", "#121212")
              .on("mouseover", (e, d) => {
                tooltip
                  .transition()
                  .duration(200)
                  .style("display", "block");
                tooltip
                  .html(
                    `${d.name_mun}<br/>Probabilidad de Prensa: ${d.prensa_pred}<br/>Probabilidad de Fiscalía: ${d.fiscalia_pred}`
                  )
                  .style("left", `${e.offsetX - 150}px`)
                  .style("top", `${e.offsetY}px`);
              })
              .on("mouseout", () => {
                tooltip
                  .transition()
                  .duration(500)
                  .style("display", "none");
              });
          }
        });
    },
    dragWindow(event) {
      event.preventDefault();
      // Get the mouse cursor position at startup
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      document.onmousemove = this.startDrag;
      document.onmouseup = this.removeEventListeners;
    },
    startDrag(event) {
      event.preventDefault();
      this.positions.movementX = this.positions.clientX - event.clientX;
      this.positions.movementY = this.positions.clientY - event.clientY;
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      // Set the elements new position
      this.$refs.scatterWindow.style.top = `${this.$refs.scatterWindow
        .offsetTop - this.positions.movementY}px`;
      this.$refs.scatterWindow.style.left = `${this.$refs.scatterWindow
        .offsetLeft - this.positions.movementX}px`;
    },
    resizeWindow(event) {
      event.preventDefault();
      // Get Scatter window dimensions and position
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      this.windowHeight = this.$refs.scatterWindow.clientHeight;
      this.windowWidth = this.$refs.scatterWindow.clientWidth;
      this.elemX = this.$refs.scatterWindow.getBoundingClientRect().left;
      this.elemY = this.$refs.scatterWindow.getBoundingClientRect().top;
      // Add mouse events to div
      document.onmousemove = this.startResize;
      document.onmouseup = this.redrawScatter;
    },
    startResize(event) {
      //Calc width and height based on window position, achieving drag effect from top left corner
      const width = this.windowWidth - (event.pageX - this.positions.clientX);
      const height =
        this.windowHeight - (event.clientY - this.positions.clientY);
      // Stop increasing Scater window on 700px width and 490px Height
      if (width < 700) {
        this.$refs.scatterWindow.style.width = `${width}px`;
        this.$refs.scatterWindow.style.left = `${this.elemX +
          (event.pageX - this.positions.clientX)}px`;
      }
      if (height < 490) {
        this.$refs.scatterWindow.style.height = `${height}px`;
        this.$refs.scatterWindow.style.top = `${this.elemY +
          (event.pageY - this.positions.clientY)}px`;
      }
    },
    redrawScatter() {
      this.drawScatter();
      document.onmouseup = null;
      document.onmousemove = null;
    },
    removeEventListeners() {
      document.onmouseup = null;
      document.onmousemove = null;
    }
  },
  watch: {
    // Watch entity change for highlingthing muns withoud drawing Scatter again
    selectedEntity() {
      this.highlightMuns();
    }
  },
  async mounted() {
    this.windowHeight = this.$refs.scatterWindow.clientHeight;
    this.windowWidth = this.$refs.scatterWindow.clientWidth;
    this.data = await this.$store.dispatch("getScatterData");
    this.drawScatter();
  }
};
</script>

<style lang="scss">
#scatter {
  position: absolute;
  z-index: 1009;
  background-color: #191a1a;
  width: 285px;
  height: 270px;
  min-width: 10%;
  max-width: 750px !important;
  min-height: 10%;
  max-height: 750px !important;
  font-size: 12px !important;
  border-radius: 9px;
  top: 60%;
  left: 74%;
  cursor: move;
  #header {
    height: auto;
    z-index: 10;
  }
  .triangle {
    content: "";
    left: 10px;
    top: calc(50% - 0.35em);
    width: 1.8em;
    height: 1.8em;
    background: linear-gradient(to bottom right, white 50%, transparent 50%);
    border-radius: 0.35em;
    cursor: nw-resize;
  }
  #scatter-graph {
    width: 100%;
    min-height: 75%;
    cursor: default;
    .scatter-tooltip {
      position: absolute;
      z-index: 2000;
      width: 250px;
      height: auto;
      padding: 10px;
      background-color: rgba($color: #ffffff, $alpha: 0.83);
      border-radius: 7px;
      color: #191a1a;
      font-weight: bold;
    }
  }
  .header-text {
    width: inherit;
    max-width: inherit;
    #info {
      cursor: default;
    }
  }
  .label {
    font-family: "Roboto Mono", monospace;
    font-weight: normal;
    font-size: 15px;
  }
  .scatter-mun {
    color: #191a1a;
    background-color: #d8d8d8;
  }
}
</style>
