<template lang="pug">
  #map-container.m-0
    #info-tabs.pt-3(v-if="selectedEntity !== ''")
      .row.title.d-flex.ml-0.mr-2
        h3.bold-font {{ selectedEntity.id_mun === "NA" ? selectedEntity.name_ent : selectedEntity.name_mun  }}
        p(v-if="selectedEntity.id_mun !== 'NA'") {{ selectedEntity.name_ent }}
      .row.tab-selection.d-flex.mx-0.mt-1
        .btn-wrapper.d-flex
          button(
            @click="chooseOption('findings')"
            v-bind:class="{ active: selectedTab === 'findings' }"
          )
            p HALLAZGOS 
          button(
            @click="chooseOption('prosecution')"
            v-bind:class="{ active: selectedTab === 'prosecution' }"
          )
            p FÍSCALIA
          button(
            @click="chooseOption('press')"
            v-bind:class="{ active: selectedTab === 'press' }"
          )
            p PRENSA
      .row.content.mx-0.mt-3
        .findings-container(v-if="selectedTab === 'findings'")
          .table-title
            p.m-0 Municipios con más hallazgos:
          .table
            b-table(
              @sort-changed="sortData"
              :items="findingsData"
              :fields="fields"
              :tbody-tr-class="rowClass"
              striped
              hover
              no-local-sorting
              borderless
              head-variant="dark"
            )
              template(v-slot:head()="data")             
                span(v-b-tooltip.hover="{ customClass: 'table-tooltip' }" :title="data.field.tooltip") {{ data.label }}           

        .prosecution-container.pl-3.pl-md-0(v-if="selectedTab === 'prosecution'")
          .row.mx-0.mb-4
            button#state-findings.light-bold-font.mr-3(
              @click="chooseProsecution('state')"
              v-bind:class="{ active: selectedProsectution === 'state' }"
            ) ESTATAL
            b-tooltip.tool(target="state-findings" triggers="hover" :popover-style="{background: '#ffffff'}")
              | Hallazgos reportados por la fiscalía estatal
            button#federal-findings.light-bold-font(
              @click="chooseProsecution('federal')"
              v-bind:class="{ active: selectedProsectution === 'federal' }"
            ) FEDERAL
            b-tooltip.tool(target="federal-findings" triggers="hover" :popover-style="{background: '#ffffff'}")
              | Hallazgos reportados por la fiscalía federal
          .state(v-if="selectedProsectution === 'state'")
            .row.mx-0.mb-4
              .col-9.left.pl-0
                p.mb-0 Total de fosas
              .col-3.centered
                p.bold-font.mb-0 {{ stateInfo.length > 0 ? stateInfo[0].total_fosas : 0 }}
            .row.extend-info.mx-0.mb-4(@click="showMoreStateInfo('bodies')")
              .col-9.left.d-flex.pl-0
                b-icon-plus-circle.mr-2(font-scale="1.2")
                p.mb-0 Total de cuerpos
              .col-3.centered
                p.bold-font.mb-0 {{ stateInfo.length > 0 ? stateInfo[0].total_cuerpos : 0 }}
            .row.more-info.mx-0.mb-4(
              :class="{ active:  moreStateInfo.indexOf('bodies') !== -1 }"
            )
              .col-9.left.pl-4
                p.mb-0 Hombres
              .col-3.centered
                p.bold-font.mb-0 {{ stateInfo.length > 0 ? stateInfo[0].cuerpos_hombre : 0 }}
              .col-9.left.pl-4
                p.mb-0 Mujeres
              .col-3.centered
                p.bold-font.mb-0 {{ stateInfo.length > 0 ? stateInfo[0].cuerpos_mujer : 0 }}
              .col-9.left.pl-4
                p.mb-0 Sin Identificar
              .col-3.centered
                p.bold-font.mb-0 {{ stateInfo.length > 0 ? stateInfo[0].cuerpos_sin_info : 0 }}
            .row.extend-info.mx-0.mb-4(@click="showMoreStateInfo('fragments')")
              .col-9.left.d-flex.pl-0
                b-icon-plus-circle.mr-2(font-scale="1.2")
                p.mb-0 Total de restos y fragmentos óseos
              .col-3.centered
                p.bold-font.mb-0 {{ stateInfo.length > 0 ? stateInfo[0].total_restos_fragmentos : 0 }}
            .row.more-info.mx-0.mb-4(
              :class="{ active: moreStateInfo.indexOf('fragments') !== -1 }"
            )
              .col-9.left.pl-4
                p.mb-0 Hombres
              .col-3.centered
                p.bold-font.mb-0 {{ stateInfo.length > 0 ? stateInfo[0].restos_fragmentos_hombre : 0 }}
              .col-9.left.pl-4
                p.mb-0 Mujeres
              .col-3.centered
                p.bold-font.mb-0 {{ stateInfo.length > 0 ? stateInfo[0].restos_fragmentos_mujer : 0 }}
              .col-9.left.pl-4
                p.mb-0 Sin Identificar
              .col-3.centered
                p.bold-font.mb-0 {{ stateInfo.length > 0 ? stateInfo[0].restos_fragmentos_sin_info : 0 }}
          .federal(v-if="selectedProsectution === 'federal'")
            .row.mx-0.mb-4
              .col-9.left.pl-0
                p.mb-0 Total de fosas
              .col-3.centered
                p.bold-font.mb-0 {{ federalInfo.length > 0 ? federalInfo[0].total_fosas : 0 }}
            .row.mx-0.mb-4
              .col-9.left.pl-0
                p.mb-0 Total de cuerpos
              .col-3.centered
                p.bold-font.mb-0 {{ federalInfo.length > 0 ? federalInfo[0].total_cuerpos : 0 }}
            .row.mx-0.mb-4
              .col-9.left.pl-0
                p.mb-0 Total de restos y fragmentos óseos
              .col-3.centered
                p.bold-font.mb-0 {{ federalInfo.length > 0 ? federalInfo[0].total_restos_fragmentos : 0 }}
            .row.extend-info.mx-0.mb-4(@click="moreInfo = !moreInfo")
              .col-9.left.d-flex.pl-0
                b-icon-plus-circle.mr-2(font-scale="1.2")
                p.mb-0 Personas identificadas
              .col-3.centered
                p.bold-font.mb-0 {{ federalInfo.length > 0 ? federalInfo[0].total_personas_identificadas : 0 }}
            .row.more-info.mx-0.mb-4(
              :class="{ active: moreInfo }"
            )
              .col-9.left.pl-4
                p.mb-0 Hombres
              .col-3.centered
                p.bold-font.mb-0 {{ federalInfo.length > 0 ? federalInfo[0].ids_masculinos : 0 }}
              .col-9.left.pl-4
                p.mb-0 Mujeres
              .col-3.centered
                p.bold-font.mb-0 {{ federalInfo.length > 0 ? federalInfo[0].ids_femeninos : 0 }}

        .press-container(v-if="selectedTab === 'press'")
          .row.extend-info.mx-0.mb-4(@click="moreInfo = !moreInfo")
            .col-9.left.d-flex.pl-0
              b-icon-plus-circle.mr-2(font-scale="1.2")
              p.mb-0 Personas identificadas
            .col-3.centered
              p.bold-font.mb-0 {{ pressInfo.length > 0 ? Number(pressInfo[0].ids_femeninos) + Number(pressInfo[0].ids_masculinos) : 0 }}
          .row.more-info.mx-0.mb-4(
            :class="{ active: moreInfo }"
          )
            .col-9.left.pl-4
              p.mb-0 Hombres
            .col-3.centered
              p.bold-font.mb-0 {{ pressInfo.length > 0 ? pressInfo[0].ids_masculinos : 0 }}
            .col-9.left.pl-4
              p.mb-0 Mujeres
            .col-3.centered
              p.bold-font.mb-0 {{ pressInfo.length > 0 ? pressInfo[0].ids_femeninos : 0 }}
          .row.mx-0.mb-4
            .col-9.right.pl-0
              p.mb-0 # Estimado alto de fosas
              b-icon-caret-up-fill.ml-3
            .col-3.centered
              p.bold-font.mb-0 {{ pressInfo.length > 0 ? pressInfo[0].fosas_alto : 0 }}         
          .row.mx-0.mb-4
            .col-9.right.pl-0
              p.mb-0 # Estimado bajo de fosas
              b-icon-caret-down-fill.ml-3
            .col-3.centered
              p.bold-font.mb-0 {{ pressInfo.length > 0 ? pressInfo[0].fosas_bajo : 0 }}
          .row.mx-0.mb-4
            .col-9.right.pl-0
              p.mb-0 # Estimado alto de cuerpos
              b-icon-caret-up-fill.ml-3
            .col-3.centered
              p.bold-font.mb-0 {{ pressInfo.length > 0 ? pressInfo[0].cuerpos_alto : 0 }}            
          .row.mx-0.mb-4
            .col-9.right.pl-0
              p.mb-0 # Estimado bajo de cuerpos
              b-icon-caret-down-fill.ml-3
            .col-3.centered
              p.bold-font.mb-0 {{ pressInfo.length > 0 ? pressInfo[0].cuerpos_bajo : 0 }}
          .row.mx-0.mb-4
            .col-9.right.pl-0
              p.mb-0 # Estimado alto de restos
              b-icon-caret-up-fill.ml-3
            .col-3.centered
              p.bold-font.mb-0 {{ pressInfo.length > 0 ? pressInfo[0].restos_alto : 0 }}            
          .row.mx-0.mb-4
            .col-9.right.pl-0
              p.mb-0 # Estimado bajo de restos
              b-icon-caret-down-fill.ml-3
            .col-3.centered
              p.bold-font.mb-0 {{ pressInfo.length > 0 ? pressInfo[0].restos_bajo : 0 }}

    Scatter(v-if="selectedEntity !== '' && !mobile")
    #map.pl-md-4(
      ref="map"
      v-bind:style="{ width: mapWidth, height: mapHeight }"
    )

</template>
<script>
import L from "leaflet";
import * as topojson from "topojson-client";
import "leaflet/dist/leaflet.css";
import Mexicali from "@/assets/topojsons/municipio de mexicali.geo.json";
import Ensenada from "@/assets/topojsons/municipio de ensenada.geo.json";
import sanQuintin from "@/assets/topojsons/municipio de san quintín.geo.json";
import sanFelipe from "@/assets/topojsons/municipio de san felipe.geo.json";
import Scatter from "@/components/Scatter";
import * as mexico from "@/assets/topojsons/mexico-small.json";

export default {
  name: "mapgraph",
  components: {
    Scatter,
  },
  data() {
    return {
      map: "",
      geojsonLayer: "",
      municipalities: "",
      selectedTab: "findings",
      selectedProsectution: "state",
      moreStateInfo: [],
      mapWidth: "93%",
      mapHeight: "500px",
      findingsData: [],
      fields: [
        {
          key: "name_mun",
          label: "",
          tooltip: "",
          sortable: false,
          tdClass: "entity",
        },
        {
          key: "media_fosas",
          label: "P",
          tooltip: "Prensa",
          sortable: true,
          tdClass: "press",
        },
        {
          key: "federal_fosas",
          label: "FF",
          tooltip: "Fiscalía Federal",
          sortable: true,
          tdClass: "federal",
        },
        {
          key: "state_fosas",
          label: "FE",
          tooltip: "Fiscalía Estatal",
          sortable: true,
          tdClass: "state",
        },
      ],
      moreInfo: false,
    };
  },
  computed: {
    mobile() {
      return this.$store.state.mobile;
    },
    selectedEntity() {
      return this.$store.state.selectedEntity;
    },
    findingsInfo() {
      return this.$store.state.entityData.data;
    },
    pressInfo() {
      return this.$store.state.entityData.data
        ? this.$store.state.entityData.data.media
        : [];
    },
    federalInfo() {
      return this.$store.state.entityData.data
        ? this.$store.state.entityData.data.federal
        : [];
    },
    stateInfo() {
      return this.$store.state.entityData.data
        ? this.$store.state.entityData.data.state
        : [];
    },
    mapData() {
      return this.$store.state.filteredMapData;
    },
  },
  methods: {
    chooseOption(opt) {
      this.selectedTab = opt;
    },
    chooseProsecution(opt) {
      this.selectedProsectution = opt;
    },
    showMoreStateInfo(selection) {
      if (this.moreStateInfo.indexOf(selection) !== -1) {
        this.moreStateInfo.splice(selection, 1);
      } else {
        this.moreStateInfo.push(selection);
      }
    },
    setMapBounds() {
      if (this.selectedEntity !== "") {
        this.mapWidth = this.mobile ? "100%" : "70%";

        let selectedState = this.municipalities.features.filter((d) => {
          return d.properties.CVE_ENT === this.selectedEntity.id_ent;
        });

        let stateGeo = {
          type: "FeatureCollection",
          features: selectedState,
        };

        if (stateGeo["features"].length > 0) {
          let geoJSON = L.geoJson(stateGeo);
          this.colorLayer();
          // Fit map to entity bounds and offset right with padding for centering
          this.map.fitBounds(
            geoJSON.getBounds(),
            this.mobile
              ? {
                  paddingTopLeft: [0, -200],
                  paddingBottomRight: [0, 0],
                }
              : {
                  paddingTopLeft: [0, 0],
                  paddingBottomRight: [280, 0],
                }
          );
        }
      } else {
        this.mapWidth = this.mobile ? "100%" : "93%";
        this.map.fitBounds(
          this.geojsonLayer.getBounds(),
          this.mobile
            ? {
                paddingTopLeft: [-150, -100],
                paddingBottomRight: [-200, 100],
              }
            : {
                paddingTopLeft: [0, 0],
                paddingBottomRight: [0, 0],
              }
        );
      }
    },
    drawMap() {
      if (this.map !== "") {
        this.map.off();
        this.map.remove();
      }

      let mapViewSettings = {
        coords: [23.6345, -102.5528],
        scale: 5,
      };

      if (this.mobile) {
        mapViewSettings.coords = [15.6345, -105.5528];
        mapViewSettings.scale = 3.5;
      }

      this.map = L.map("map").setView(
        mapViewSettings.coords,
        mapViewSettings.scale
      );

      this.map.scrollWheelZoom.disable();

      L.tileLayer(
        "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
        {
          attribution:
            '© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> <strong><a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a></strong>',
          tileSize: 512,
          maxZoom: 18,
          zoomOffset: -1,
          id: "mapbox/dark-v10",
          accessToken:
            "pk.eyJ1IjoibmVsbHktcmFtaXJleiIsImEiOiJjanJ3N3NyaHUwOGV0NDVuY2FleXc5YXB3In0.r1Gvt3uxgR8o36CcDMd3Bw",
        }
      ).addTo(this.map);
      this.geojsonLayer = L.geoJSON(this.municipalities, {
        style: {
          fillColor: "transparent",
          fillOpacity: 0.7,
          weight: 0.5,
          color: "#8e9aaf",
          dashArray: "1",
          opacity: 0.7,
        },
      })
        .bindTooltip((layer) => layer.feature.properties.NOMGEO)
        .addTo(this.map);

      if (!this.mobile) {
        this.geojsonLayer.on("click", (l) => {
          let petitionEntity = this.$store.state.entities.filter((e) => {
            return (
              Number(e.id_ent) === Number(l.layer.feature.properties.CVE_ENT) &&
              Number(e.id_mun) === Number(l.layer.feature.properties.CVE_MUN)
            );
          });
          this.$store.dispatch("getEntityData", petitionEntity[0]);
        });
      }
    },
    colorLayer() {
      this.geojsonLayer.eachLayer((layer) => {
        // Default Style for non data entities
        layer.setStyle({
          fillColor: "transparent",
          fillOpacity: 0.7,
          weight: 0.5,
          color: "#8e9aaf",
          dashArray: "1",
          opacity: 0.7,
        });

        // Highlighting selected state/mun]
        // First just checking if there is a entity selection and limit de ciclying to that selection
        if (
          this.$store.state.selectedEntity !== "" &&
          layer.feature.properties.CVE_ENT ===
            this.$store.state.selectedEntity.id_ent
        ) {
          // Setting general styling for the selected state of the entity
          layer.setStyle({
            fillColor: "#191a1a",
            fillOpacity: 0.7,
          });
          if (
            layer.feature.properties.CVE_MUN ===
            this.$store.state.selectedEntity.id_mun
          ) {
            // If the selected entity is a mun just highlight that mun with more opacity and white border
            layer.setStyle({
              fillOpacity: 0.9,
              weight: 2.5,
              color: "white",
              dashArray: "1",
              opacity: 1,
            });
          } else if (this.$store.state.selectedEntity.id_mun === "NA") {
            // If the selected entity is an state highlight all the muns
            layer.setStyle({
              fillOpacity: 0.9,
              weight: 1.5,
              color: "white",
              dashArray: "1",
              opacity: 1,
            });
          }
        }

        let mun = this.mapData.filter((d) => {
          return (
            d.id_mun === parseInt(layer.feature.properties.CVE_MUN) &&
            d.id_ent === parseInt(layer.feature.properties.CVE_ENT)
          );
        });

        if (mun.length > 0) {
          // Coloring all entities with data info
          const color = this.getMunColor(mun[0].id_source);
          layer.setStyle({
            fillColor: color,
            fillOpacity: 0.8,
          });
        }
      });
    },
    getMunColor(source) {
      switch (source) {
        case 1:
          return "#ffbb5e";
        case 2:
          return "#12b2aa";
        case 3:
          return "#7473ff";
        case 4:
          return "#8bb784";
        case 5:
          return "#b494b5";
        case 6:
          return "#4493d5";
        case 7:
          return "#e5e3e3";
      }
    },
    sortData(data) {
      let dataSelection = data.graves ? data : this.findingsInfo;
      let sortBy = data.sortBy ? data.sortBy : "media_fosas";
      let sortDirection = data.sortDesc === true ? "desc" : "asc";

      this.findingsData = dataSelection.graves
        .filter((d, i, a) => {
          return a.findIndex((t) => t.mun === d.mun) === i && d.mun !== 0;
        })
        .sort((a, b) => {
          if (sortDirection === "desc") {
            return Number(a[sortBy] - Number(b[sortBy]));
          }
          return Number(b[sortBy]) - Number(a[sortBy]);
        })
        .slice(0, 5);

      if (this.selectedEntity.id_mun !== "NA") {
        let foundSelectedMun = this.findingsData.find(
          (d) => d.mun === Number(this.selectedEntity.id_mun)
        );

        if (!foundSelectedMun) {
          this.findingsData.push(
            dataSelection.graves.find(
              (m) => m.mun === Number(this.selectedEntity.id_mun)
            )
          );
        }
      }
    },
    rowClass(item) {
      if (
        this.selectedEntity.id_mun !== "NA" &&
        item.mun === Number(this.selectedEntity.id_mun)
      ) {
        return "active-mun";
      }
      return null;
    },
  },
  watch: {
    selectedEntity() {
      this.setMapBounds();
    },
    mapData() {
      this.colorLayer();
    },
    findingsInfo(val) {
      this.sortData(val);
    },
  },
  mounted() {
    if (this.mobile) {
      this.mapWidth = "100%";
      this.mapHeight = "300px";
    }
    this.municipalities = topojson.feature(
      mexico.default,
      mexico.default.objects.municipios_mx_feb2018
    );

    this.municipalities.features.push(Mexicali);
    this.municipalities.features.push(Ensenada);
    this.municipalities.features.push(sanQuintin);
    this.municipalities.features.push(sanFelipe);

    this.drawMap();
  },
};
</script>

<style lang="scss">
@import "@/global.scss";
#map-container {
  display: flex;
  width: inherit;
  height: inherit;
  #info-tabs {
    height: inherit;
    width: 25%;
    justify-content: center;
    background-color: #121212;
    .tab-selection,
    .content {
      justify-content: center;
      text-align: center;
    }
    .title {
      justify-content: center;
      flex-direction: column;
    }
    .btn-wrapper button {
      outline: none;
      background: transparent;
      border: 1px solid transparent;
      margin: 0px !important;
      line-height: 19px;
      font-size: 13px;
      white-space: pre;
      color: white;
    }
    .active {
      font-weight: bold;
      p {
        border-bottom: 2px solid white;
      }
    }
  }
  .findings-container {
    .table-title p {
      font-size: 13px;
    }
    .table {
      font-size: 13px;
      th {
        background-color: $black;
        color: white;
      }
      td.entity {
        color: white;
        border: none !important;
      }
      td.press {
        color: #ffbb5e;
      }
      td.federal {
        color: #7473ff;
      }
      td.state {
        color: #12b2aa;
      }
      td {
        border-left: 1px solid white;
        vertical-align: middle;
      }
      .active-mun {
        background-color: rgba(216, 216, 216, 0.42);
      }
    }
  }
  .prosecution-container {
    button {
      outline: none;
      border: 1px solid white;
      line-height: 19px;
      font-size: 13px;
      white-space: pre;
      background-color: black;
      color: white;
      padding: 3px 6px;
    }
    .active {
      border: 1px solid transparent;
      background-color: #7473ff;
    }
  }
  .prosecution-container,
  .press-container {
    font-size: 18px;
    .centered {
      align-self: center;
    }
    .left {
      align-items: center;
      font-size: 14px;
      text-align: left;
    }
    .right {
      font-size: 14px;
      text-align: right;
      display: flex;
      align-items: center;
      .b-icon {
        color: #ffbb5e;
      }
    }
    .extend-info {
      cursor: pointer;
    }
    .more-info {
      display: none;
      p {
        border: none !important;
      }
    }
    .more-info.active {
      display: flex !important;
      background-color: $black;
    }
  }
  #map {
    height: inherit;
    max-height: 500px;
  }
}
@media (max-width: 767.98px) {
  #map-container {
    flex-direction: column;
    #info-tabs {
      width: 100%;
      order: 2;
    }
    #map {
      order: 1;
    }
  }
}
</style>
